import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { IdentityStatusEnum } from "@/lib/enum/identity-status.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { handleError } from "@/lib/utility/handleError";
import authService from "@/services/mrfiktiv/services/authService";
import {
  MrfiktivCreateUserDtoGen,
  MrfiktivIdentityInformationViewModelGen,
  MrfiktivNotificationConfigDtoGen,
  MrfiktivPartnerUserViewModelGen,
  MrfiktivPermissionDtoGen,
  MrfiktivUpdateUserDtoGen,
  MrfiktivUserViewmodelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import partnerUserService from "@/services/shared/partnerUserService";
import { AuthRolesEnum } from "@/store/enum/authRolesEnum";
import { AdminUserDataAccessLayer } from "@/store/modules/access-layers/admin-user.access-layer";
import { PartnerUserAccessLayer } from "@/store/modules/access-layers/partner-user.access-layer";
import adminUserService from "../services/shared/adminUserService";
import { Address, IAddress } from "./address.entity";
import { Contact, IContact } from "./contact.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";

class AuthInfo {
  username = "";
}

@IsFilterable
class UserBase implements MrfiktivUserViewmodelGen, Partial<MrfiktivIdentityInformationViewModelGen> {
  /** The users first name */
  @FilterConfig({ type: FilterTypes.STRING })
  firstName: string;

  /** The users last name */
  @FilterConfig({ type: FilterTypes.STRING })
  lastName: string;

  /** The users contact details */
  @FilterConfig({ type: Contact })
  contact: IContact;

  /** The id of the user */
  id: string;

  /** The users userName */
  @FilterConfig({ type: FilterTypes.STRING })
  userName: string;

  /**
   * The country code for the user (where the user signed up from)
   * @example DE
   */
  countryCode?: CountryCodeEnum;

  /** The users address */
  @FilterConfig({ type: Address })
  address: IAddress;

  /** Marketing OptIn of the User */
  @FilterConfig({ type: FilterTypes.BOOLEAN })
  isMarketingOptIn: boolean;

  /** The User Accepted the AGBS */
  @FilterConfig({ type: FilterTypes.BOOLEAN })
  isTermsAccepted: boolean;

  /** The Companyname */
  @FilterConfig({ type: FilterTypes.STRING })
  company: string;

  @FilterConfig({
    displayName: "language",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(LanguageCodeEnum).map(v => {
        return {
          text: `enums.LanguageCodeEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  language: LanguageCodeEnum;

  timestamps: ITimestamp;

  /** The id of the user */
  @FilterConfig({ type: FilterTypes.OBJECT_ID })
  get _id() {
    return this.id;
  }

  authInfo: AuthInfo;

  roles: AuthRolesEnum[];

  permission: MrfiktivPermissionDtoGen[];

  /**
   * Can user eligible for tax
   * @example true
   */
  isTaxDeductible?: boolean;

  /**
   * taxnumber of user
   * @example DE 123 456 78
   */
  @FilterConfig({ type: FilterTypes.STRING })
  taxnumber?: string;

  /**
   * Is user company user
   * @example true
   */
  @FilterConfig({ type: FilterTypes.BOOLEAN })
  isCompany?: boolean;

  partnerId?: string;

  @FilterConfig({ type: FilterTypes.STRING })
  externalId?: string;

  status?: IdentityStatusEnum;

  notificationConfig: MrfiktivNotificationConfigDtoGen = {
    assigned: {
      isMail: false,
      isPush: false
    }
  };

  constructor(
    user?: Partial<MrfiktivUserViewmodelGen & MrfiktivIdentityInformationViewModelGen & { partnerId: string }>
  ) {
    this.id = user?._id ?? user?.id ?? "";
    this.address = new Address(user?.address);
    this.contact = new Contact(user?.contact);
    this.firstName = user?.firstName ?? "";
    this.lastName = user?.lastName ?? "";
    this.userName = user?.userName ?? "";
    this.countryCode = user?.countryCode as CountryCodeEnum;
    this.isMarketingOptIn = user?.isMarketingOptIn ?? false;
    this.isTermsAccepted = user?.isTermsAccepted ?? false;
    this.company = user?.company ?? "";
    this.timestamps = new Timestamp(user?.timestamps);
    this.authInfo = (user?.authInfo ?? {}) as AuthInfo;
    this.roles = (user?.roles ?? []) as AuthRolesEnum[];
    this.permission = user?.permission ?? [];
    this.isTaxDeductible = user?.isTaxDeductible;
    this.taxnumber = user?.taxnumber;
    this.isCompany = user?.isCompany;
    this.partnerId = user?.partnerId;
    this.status = user?.status as IdentityStatusEnum;
    if (user?.notificationConfig) {
      this.notificationConfig = user?.notificationConfig;
    }
    this.language = user?.language as LanguageCodeEnum;
    this.externalId = user?.externalId;
  }

  map(user?: MrfiktivUserViewmodelGen) {
    this.id = user?._id ?? user?.id ?? "";
    this.address = new Address(user?.address);
    this.contact = new Contact(user?.contact);
    this.firstName = user?.firstName ?? "";
    this.lastName = user?.lastName ?? "";
    this.userName = user?.userName ?? "";
    this.countryCode = user?.countryCode as CountryCodeEnum;
    this.isMarketingOptIn = user?.isMarketingOptIn ?? false;
    this.isTermsAccepted = user?.isTermsAccepted ?? false;
    this.company = user?.company ?? "";
    this.timestamps = new Timestamp(user?.timestamps);
    this.authInfo = (user?.authInfo ?? {}) as AuthInfo;
    this.roles = (user?.roles ?? []) as AuthRolesEnum[];
    this.permission = user?.permission ?? [];
    this.isTaxDeductible = user?.isTaxDeductible;
    this.taxnumber = user?.taxnumber;
    this.isCompany = user?.isCompany;
    if (user?.notificationConfig) {
      this.notificationConfig = user?.notificationConfig;
    }
    this.language = user?.language as LanguageCodeEnum;
    this.externalId = user?.externalId;
  }

  mapIdentity(user?: MrfiktivIdentityInformationViewModelGen) {
    this.status = user?.status as IdentityStatusEnum;
  }
}

class PartnerUserBase extends UserBase
  implements IEntity<MrfiktivPartnerUserViewModelGen & Partial<MrfiktivIdentityInformationViewModelGen>> {
  partnerId: string;

  constructor(
    user?: Partial<MrfiktivPartnerUserViewModelGen & Partial<MrfiktivIdentityInformationViewModelGen>> & {
      partnerId: string;
    }
  ) {
    super(user);

    this.partnerId = user?.partnerId ?? "";
  }

  map(user?: MrfiktivUserViewmodelGen | MrfiktivPartnerUserViewModelGen) {
    if (!user) {
      return;
    }

    super.map(user as MrfiktivUserViewmodelGen);

    if ((user as MrfiktivPartnerUserViewModelGen).partnerId) {
      this.partnerId = (user as MrfiktivPartnerUserViewModelGen).partnerId;
    }
  }

  async fetch(): Promise<this> {
    if (!this.partnerId) {
      throw new Error("partnerId is missing");
    }

    const user = await partnerUserService.findOne(this.partnerId, this.id);

    this.map(user);

    PartnerUserAccessLayer.set(this);

    return this;
  }

  async fetchStatus(): Promise<this> {
    try {
      if (!this.partnerId) {
        throw new Error("partnerId is missing");
      }

      const { user, identity } = await partnerUserService.getStatus(this.partnerId, this.id);

      this.map(user);
      this.mapIdentity(identity);

      PartnerUserAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async create(): Promise<this> {
    try {
      if (!this.partnerId) {
        throw new Error("partnerId is missing");
      }

      const user = await partnerUserService.create(this.partnerId, {
        firstName: this.firstName,
        lastName: this.lastName,
        userName: this.userName,
        address: this.address,
        company: this.company,
        contact: this.contact,
        countryCode: this.countryCode,
        isCompany: this.isCompany,
        isMarketingOptIn: this.isMarketingOptIn,
        isTaxDeductible: this.isTaxDeductible,
        isTermsAccepted: this.isTermsAccepted,
        taxnumber: this.taxnumber
      });

      this.map(user);

      PartnerUserAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async delete(): Promise<void> {
    try {
      if (!this.partnerId) {
        throw new Error("partnerId is missing");
      }

      const user = await partnerUserService.remove(this.partnerId, this.id);

      this.map(user);

      PartnerUserAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }
  }

  isArchived() {
    const partnerPermissions = this.permission.filter(permission => permission.id === this.partnerId);
    /**
     * user is archived if the only permission for the partner is PARTNER:read
     */
    if (partnerPermissions?.length === 1 && partnerPermissions[0].type === ResourceEnum.PARTNER) {
      return true;
    }

    return false;
  }
}

class AdminUserBase extends UserBase
  implements IEntity<MrfiktivUserViewmodelGen & Partial<MrfiktivIdentityInformationViewModelGen>> {
  constructor(
    user?:
      | Partial<MrfiktivUserViewmodelGen & Partial<MrfiktivIdentityInformationViewModelGen>>
      | MrfiktivCreateUserDtoGen
  ) {
    super(user);
  }

  async fetch(): Promise<this> {
    try {
      const user = await adminUserService.get(this.id);

      this.map(user);
      AdminUserDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async removePermission(permissions: MrfiktivPermissionDtoGen[]) {
    try {
      const updatedUser = await adminUserService.removePermission(this.id, permissions);

      this.map(updatedUser);
      AdminUserDataAccessLayer.set(this);
    } catch (error) {
      handleError(error);
    }

    return this;
  }

  async addPermissions(permissions: MrfiktivPermissionDtoGen[]) {
    try {
      const updatedUser = await adminUserService.addPermissions(this.id, permissions);

      this.map(updatedUser);
      AdminUserDataAccessLayer.set(this);
    } catch (error) {
      handleError(error);
    }

    return this;
  }

  async addPermissionsByUser() {
    try {
      const updatedUser = await adminUserService.addPermissionsByUser(this);

      this.map(updatedUser);
      AdminUserDataAccessLayer.set(this);
    } catch (error) {
      handleError(error);
    }

    return this;
  }

  async updateRoles() {
    try {
      const updatedUser = await adminUserService.updateRole(this.id, this.roles);
      this.map(updatedUser);
      AdminUserDataAccessLayer.set(this);
    } catch (error) {
      handleError(error);
    }

    return this;
  }

  async update() {
    try {
      const updatedUser = await adminUserService.update(this.id, {
        firstName: this.firstName,
        lastName: this.lastName,
        address: this.address,
        contact: this.contact,
        isMarketingOptIn: this.isMarketingOptIn,
        company: this.company,
        isTaxDeductible: this.isTaxDeductible,
        taxnumber: this.taxnumber,
        isCompany: this.isCompany,
        language: this.language
      });

      this.map(updatedUser);
      AdminUserDataAccessLayer.set(this);
    } catch (error) {
      handleError(error);
    }

    return this;
  }

  async updateFromDto(dto: MrfiktivUpdateUserDtoGen) {
    try {
      const updatedUser = await adminUserService.update(this.id, dto);

      this.map(updatedUser);
      AdminUserDataAccessLayer.set(this);
    } catch (error) {
      handleError(error);
    }

    return this;
  }

  async invite() {
    try {
      const createdUser = await adminUserService.invite({
        firstName: this.firstName,
        lastName: this.lastName,
        userName: this.userName,
        countryCode: this.countryCode,
        address:
          this.address.city && this.address.street && this.address.zip && this.address.countryCode
            ? this.address
            : undefined,
        contact: this.contact.email && this.contact.phone ? this.contact : undefined,
        company: this.company,
        isMarketingOptIn: this.isMarketingOptIn,
        isTermsAccepted: this.isTermsAccepted,
        isTaxDeductible: this.isTaxDeductible,
        isCompany: this.isCompany,
        taxnumber: this.taxnumber
      });

      this.map(createdUser);
      AdminUserDataAccessLayer.set(this);
    } catch (error) {
      handleError(error);
    }

    return this;
  }

  async resendInvitation(silent?: boolean) {
    try {
      const user = await adminUserService.resendInvitation(this.id);

      this.map(user);
      AdminUserDataAccessLayer.set(this);
    } catch (error) {
      handleError(error);
      if (!silent) throw error;
    }

    return this;
  }

  async getIdentity() {
    try {
      if (!this.userName) {
        throw new Error("userName is missing");
      }
      const identityInfo = await authService.getIdentity(this.userName);
      this.mapIdentity(identityInfo);
      AdminUserDataAccessLayer.set(this);
    } catch (error) {
      handleError(error);
    }

    return this;
  }

  async delete() {
    try {
      const user = await adminUserService.delete(this.id);
      this.map(user);
      AdminUserDataAccessLayer.delete(this);
    } catch (error) {
      handleError(error);
    }
  }
}

type IUser = UserBase;
const User = UserBase;
type IPartnerUser = PartnerUserBase;
const PartnerUser = Filter.createForClass(PartnerUserBase);
type IAdminUser = AdminUserBase;
const AdminUser = Filter.createForClass(AdminUserBase);

export { AdminUser, IAdminUser, IPartnerUser, IUser, PartnerUser, User };

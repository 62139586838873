import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { MessageFolderEnum } from "@/lib/enum/message-folder.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { downloadUrlFromRemoteOrigin } from "@/lib/utility/downloadFileFunc";
import { GoToHelper } from "@/lib/utility/goToHelper";
import {
  MrfiktivMessageContentGen,
  MrfiktivMessageMetaGen,
  MrfiktivPartnerMessageViewModelGen,
  MrfiktivReferenceGen,
  MrfiktivShortUserViewModelGen,
  MrfiktivUpdateMessageDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import messageService from "@/services/shared/messageService";
import { ThgPartnerMessageViewModelGen } from "@/services/thg/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityLogDataAccessLayer } from "@/store/modules/access-layers/activity-log-service.access-layer";
import { PartnerMessageDataAccessLayer } from "@/store/modules/access-layers/partner-message.access-layer";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import VueRouter from "vue-router";
import { ActivityLog } from "./activity-log.entity";
import { ByAt, IByAt } from "./by-at.entity";
import { IReference, Reference } from "./reference.entity";
import { ITimestamp, Timestamp, TimestampDocument } from "./timestamp.entity";
import { IPartnerMessageAttachment, PartnerMessageAttachment } from "./partner-message-attachment.entity";
import JSZip from "jszip";

export const messageFolderIconMap = new Map([
  [MessageFolderEnum.ARCHIVE, "mdi-inbox"],
  [MessageFolderEnum.INBOX, "mdi-inbox-arrow-down"],
  [MessageFolderEnum.JUNK, "mdi-inbox-remove"],
  [MessageFolderEnum.OUTBOX, "mdi-inbox-arrow-up"]
]);

@IsFilterable
class PartnerMessageBase implements MrfiktivPartnerMessageViewModelGen, ThgPartnerMessageViewModelGen {
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.message.id",
    config: {
      itemCallback: () => PartnerMessageDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-message"
    }
  })
  id = "";

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.message.to"
  })
  to: string;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.message.from"
  })
  from: string;

  @FilterConfig({
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(MessageFolderEnum).map(v => {
        return {
          text: `enums.MessageFolderEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    displayName: "objects.message.folder"
  })
  folder: MessageFolderEnum;

  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.message.isRead",
    width: "200"
  })
  isRead: boolean;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.ticket.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId?: string | undefined;

  content: MrfiktivMessageContentGen;
  meta?: MrfiktivMessageMetaGen | undefined;

  @FilterConfig({
    displayName: "objects.ticket.assignees",
    type: FilterTypes.OBJECT_ID,
    width: "120",
    config: {
      itemCallback: () => PartnerUserModule.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  assignees: string[] = [];

  @FilterConfig({
    type: ByAt
  })
  viewed: IByAt[] = [];

  assigneesDetails?: MrfiktivShortUserViewModelGen[] | undefined = [];
  refs: IReference[] | undefined = [];
  hasAttachments: boolean;
  attachments: IPartnerMessageAttachment[];
  virusVerdict?: string | undefined;
  spamVerdict?: string | undefined;
  inbound?: string | undefined;
  url?: string | undefined;
  timestamp: ITimestamp;
  partnerId: string;

  attachmentZip: ArrayBuffer | null = null;
  attachmentZipFiles: File[] = [];

  constructor(
    message?: Partial<PartnerMessageBase | MrfiktivPartnerMessageViewModelGen | ThgPartnerMessageViewModelGen>
  ) {
    this.id = message?.id || "";
    this.partnerId = message?.partnerId || "";
    this.userId = message?.userId;
    this.to = message?.to || "";
    this.from = message?.from || "";
    this.folder = (message?.folder || "outbox") as MessageFolderEnum;
    this.isRead = message?.isRead ?? false;
    this.hasAttachments = message?.hasAttachments || false;
    this.attachments = (message?.attachments || []).map(a => new PartnerMessageAttachment(a));
    this.content = message?.content || { subject: "", body: "" };
    this.meta = message?.meta ?? undefined;
    this.timestamp = new Timestamp(message?.timestamp as ITimestamp);
    this.assignees = message?.assignees || [];
    this.refs = (message?.refs ?? [])?.map((r: IReference | MrfiktivReferenceGen) => new Reference(r));
    this.virusVerdict = message?.virusVerdict;
    this.spamVerdict = message?.spamVerdict;
    this.url = message?.url;
    this.viewed = (message?.viewed || []).map(v => new ByAt(v));
    this.inbound = message?.inbound;
  }

  private map(message?: MrfiktivPartnerMessageViewModelGen | ThgPartnerMessageViewModelGen) {
    if (!message) return;
    this.id = message?.id || "";
    this.partnerId = message?.partnerId || "";
    this.userId = message?.userId;
    this.to = message?.to || "";
    this.from = message?.from || "";
    this.folder = (message?.folder || "outbox") as MessageFolderEnum;
    this.isRead = message?.isRead || false;
    this.hasAttachments = message?.hasAttachments || false;
    this.attachments = (message?.attachments || []).map(a => new PartnerMessageAttachment(a));
    this.content = message?.content || { subject: "", body: "" };
    this.meta = message?.meta ?? undefined;
    this.timestamp = new Timestamp(message?.timestamp as ITimestamp);
    this.assignees = message?.assignees || [];
    this.refs = (message?.refs ?? [])?.map((r: IReference | MrfiktivReferenceGen) => new Reference(r));
    this.virusVerdict = message?.virusVerdict;
    this.spamVerdict = message?.spamVerdict;
    this.url = message?.url;
    this.viewed = (message?.viewed || []).map(v => new ByAt(v));
    this.inbound = message?.inbound;
  }

  async fetch(): Promise<this> {
    if (!this.id) {
      throw new Error("No id or number provided");
    }

    const fetched = await messageService.getMessage(this.partnerId, this.id);

    this.map(fetched);
    PartnerMessageDataAccessLayer.set(this);

    return this;
  }

  async download(): Promise<void> {
    if (!this.url) {
      throw new Error("no url");
    }

    let name = "";
    const addUnderscore = () => (name += "_");

    if (this.from) name += this.from;
    if (name && this.to) addUnderscore();
    if (this.to) name += this.to;
    if (name) addUnderscore();
    name += this.timestamp.created;
    name += ".eml";

    await downloadUrlFromRemoteOrigin(this.url, name);
  }

  async updatePartial(data: MrfiktivUpdateMessageDtoGen): Promise<this> {
    const updated = await messageService.updateMessage(this.partnerId, this.id, data);

    this.map(updated);

    PartnerMessageDataAccessLayer.set(this);

    return this;
  }

  async archive() {
    await this.updatePartial({ folder: MessageFolderEnum.ARCHIVE });
  }

  /**
   * Create an activity log for assignee changes
   * @param newAssignees The ids of the new assignees of the ticket
   * @param activityType Whether the assignee was added or removed
   */
  async createAssigneeActivity(
    activityType: ActivityTypeEnum.CREATE_ASSIGNEE | ActivityTypeEnum.DELETE_ASSIGNEE,
    newAssignees?: string[]
  ) {
    if (!newAssignees?.length) return;

    await new ActivityLog().createAssigneeActivity({
      partnerId: this.partnerId,
      source: {
        refType: ResourceEnum.MESSAGE,
        refId: this.id
      },
      newAssignees,
      activityType
    });
  }

  async goToDetail(router: VueRouter) {
    await new GoToHelper(router).go({
      name: "PartnerMessagesDetailView",
      params: { messageId: this.id, partnerId: this.partnerId }
    });
  }

  async markUnread(): Promise<this> {
    const updated = await this.updatePartial({ viewed: [] });

    this.map(updated);

    PartnerMessageDataAccessLayer.set(this);

    return this;
  }

  async populateAttachmentZip() {
    if (!this.id) {
      throw new Error("No id provided");
    }
    if (!this.partnerId) {
      throw new Error("No partnerId provided");
    }

    const attachmentZip = await messageService.getAttachments(this.partnerId, this.id);
    this.attachmentZip = attachmentZip;

    const attachmentZipFiles = (await this.unpackAttachmentZip(attachmentZip)) ?? [];
    this.attachmentZipFiles.splice(0, this.attachmentZipFiles.length, ...attachmentZipFiles);
  }

  private async unpackAttachmentZip(attachmentZip: ArrayBuffer) {
    if (!attachmentZip) return;

    const filesObject = (await JSZip.loadAsync(attachmentZip)).files;
    const filesAsync = Object.values(filesObject).map(this.jsZipFileToFile);
    const files = await Promise.all(filesAsync);

    // files have type application/octet-stream. Correct this!
    return files.map(f => {
      for (const imgExt of [".jpg", ".jpeg", ".gif", ".bmp", ".tiff", ".tif", ".png"]) {
        if (f.type === "application/octet-stream" && f.name.endsWith(imgExt)) {
          return new File([f], f.name, { type: `image/${imgExt.split(".").pop()}` });
        }
      }
      for (const pdfExt of [".pdf"]) {
        if (f.type === "application/octet-stream" && f.name.endsWith(pdfExt)) {
          return new File([f], f.name, { type: "application/pdf" });
        }
      }

      return f;
    });
  }

  private async jsZipFileToFile(jsZipFile: JSZip.JSZipObject): Promise<File> {
    const name = jsZipFile.name;
    const blob = await jsZipFile.async("blob");
    return new File([blob], name, { type: "application/octet-stream" });
  }

  async create(): Promise<this> {
    const created = await messageService.sendMessage(this.partnerId, {
      content: this.content,
      meta: this.meta ?? { contentFormat: "html", medium: "email" },
      to: this.to,
      refs: this.refs
    });

    this.map(created);
    PartnerMessageDataAccessLayer.set(this);

    for (const ref of created?.refs ?? []) {
      ActivityLogDataAccessLayer.set(
        new ActivityLog({
          id: ref.refType + this.id,
          partnerId: this.partnerId,
          source: { refType: ref.refType, refId: ref.refId },
          target: [{ refType: BackendResourceEnum.MESSAGE, refId: this.id }],
          actionType: ActionEnum.CREATE,
          activity: ActivityTypeEnum.MESSAGE,
          userId: UserModule.userId,
          timestamp: new TimestampDocument(this.timestamp)
        })
      );
    }

    return this;
  }
}
type IPartnerMessage = PartnerMessageBase;
const PartnerMessage = Filter.createForClass(PartnerMessageBase);

export { IPartnerMessage, PartnerMessage };

import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { IReport } from "@/models/report.entity";
import { MrfiktivReportControllerFindAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { ProgressStatusEnum } from "../enum/partner/progress.status.enum";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ReportDataAccessLayer } from "./access-layers/report.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { ReportPageDataProvider } from "./page-data-provider/report.page-data-provider";
import { PartnerUserModule } from "./partner-user.store";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";

@Module({
  dynamic: true,
  namespaced: true,
  name: "reportPagination",
  store
})
export class ReportPaginationStore extends PaginatedBaseStore<IReport, MrfiktivReportControllerFindAllParamsGen> {
  _data = ReportDataAccessLayer;
  _pageProvider = ReportPageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);
  protected _isLoadAll = false;

  filters: IPageFilterElement[] = [
    new PageFilterElement({
      value: ProgressStatusEnum.DRAFT,
      key: "progressStatus",
      operation: PageFilterOperationEnum.NOT_EQUAL
    })
  ];

  hiddenFilter: IPageFilterElement[] = [
    new PageFilterElement({ key: "progressStatus", value: ProgressStatusEnum.DELETED, operation: "$ne" })
  ];

  filterOptions: PaginationFilterListElement[] = [
    { key: "timestamps.created", type: PageFilterTypes.DATE, displayName: "objects.timestamp.created" },
    { key: "numberplate", type: PageFilterTypes.STRING, displayName: "objects.report.numberplate" },
    { key: "title", type: PageFilterTypes.STRING, displayName: "objects.report.title" },
    { key: "externalId", type: PageFilterTypes.STRING, displayName: "objects.report.externalId" },
    {
      key: "progressStatus",
      type: PageFilterTypes.ENUM,
      config: {
        items: Object.values(ProgressStatusEnum)
          .filter(v => v !== ProgressStatusEnum.DELETED)
          .map(reportStatus => {
            return {
              text: `partner.common.reportProgressStatus.${reportStatus}`,
              value: reportStatus
            } as IVSelectItem;
          }),
        itemValue: "value"
      },
      displayName: "objects.report.progressStatus"
    },
    { key: "customerName", type: PageFilterTypes.STRING, displayName: "objects.report.customerName" },
    {
      key: "assignees",
      type: PageFilterTypes.OBJECT_ID,
      displayName: "objects.report.assignees",
      config: {
        items: PartnerUserModule?.paginationList ?? [],
        mapItemToComponent: (item: any) => {
          return { item };
        },
        itemValue: "id",
        component: "refs-user"
      }
    },
    { key: "customerContact.email", type: PageFilterTypes.STRING, displayName: "objects.report.customerContact.email" },
    { key: "customerContact.phone", type: PageFilterTypes.STRING, displayName: "objects.report.customerContact.phone" },
    { key: "marketingOptIn", type: PageFilterTypes.BOOLEAN, displayName: "objects.report.marketingOptIn" },

    {
      key: "registrationResults.firstregistrationDay",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.firstregistrationDay"
    },
    {
      key: "registrationResults.firstregistrationMonth",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.firstregistrationMonth"
    },
    {
      key: "registrationResults.firstregistrationYear",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.firstregistrationYear"
    },

    {
      key: "registrationResults.street",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.street"
    },
    {
      key: "registrationResults.zipCode",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.zipCode"
    },
    {
      key: "registrationResults.city",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.city"
    },
    {
      key: "registrationResults.huYear",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.huYear"
    },
    {
      key: "registrationResults.huMonth",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.huMonth"
    },
    {
      key: "registrationResults.manufacturerNameCode",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.manufacturerNameCode"
    },
    {
      key: "registrationResults.manufacturerTypeCode",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.manufacturerTypeCode"
    },
    {
      key: "registrationResults.driveTyp",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.driveTyp"
    },
    {
      key: "registrationResults.identificationnumber",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.identificationnumber"
    },
    {
      key: "registrationResults.numberplate",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.numberplate"
    },
    {
      key: "registrationResults.manufacturerName",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.manufacturerName"
    },
    {
      key: "registrationResults.manufacturerType",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.manufacturerType"
    },
    {
      key: "registrationResults.vehicleClass",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.vehicleClass"
    },
    {
      key: "registrationResults.vehicleClass",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.registrationResults.vehicleClass"
    },
    {
      key: "broker.name",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.broker.name"
    },
    {
      key: "broker.number",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.broker.number"
    },
    {
      key: "insurances.name",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.insurances.name"
    },
    {
      key: "insurances.name",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.insurances.name"
    },
    {
      key: "insurances.number",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.insurances.number"
    },
    {
      key: "insurances.type",
      type: PageFilterTypes.STRING,
      displayName: "objects.report.insurances.type"
    },
    { key: "message", type: PageFilterTypes.STRING, displayName: "objects.report.message" },
    { key: "datePreference", type: PageFilterTypes.STRING, displayName: "objects.report.datePreference" },
    { key: "_id", type: PageFilterTypes.OBJECT_ID, displayName: "objects.report._id" }
  ].map(f => new PaginationFilterListElement(f));
}

export const ReportPaginationModule = getModule(ReportPaginationStore);

import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { MrfiktivRegistrationDtoGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFilterable
class RegistrationBase implements MrfiktivRegistrationDtoGen {
  /**
   * @inheritdoc
   */
  firstname?: string;

  /**
   * @inheritdoc
   */
  name?: string;

  /**
   * @inheritdoc
   */
  street?: string;

  /**
   * @inheritdoc
   */
  zipCode?: string;

  /**
   * @inheritdoc
   */
  city?: string;

  /**
   * @inheritdoc
   */
  huYear?: string;

  /**
   * @inheritdoc
   */
  huMonth?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.manufacturerNameCode"
  })
  manufacturerNameCode?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.manufacturerTypeCode"
  })
  manufacturerTypeCode?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.driveTyp"
  })
  driveTyp?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.identificationnumber"
  })
  identificationnumber?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.numberplate"
  })
  numberplate?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.firstregistrationDay"
  })
  firstregistrationDay?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.firstregistrationMonth"
  })
  firstregistrationMonth?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.firstregistrationYear"
  })
  firstregistrationYear?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.manufacturerName"
  })
  manufacturerName?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.manufacturerType"
  })
  manufacturerType?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.manufacturerDescription"
  })
  manufacturerDescription?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.vehicleClass"
  })
  vehicleClass?: string;

  /**
   * @inheritdoc
   */
  typeApproval?: string;

  /**
   * @inheritdoc
   */
  typeApprovalDay?: string;

  /**
   * @inheritdoc
   */
  typeApprovalMonth?: string;

  /**
   * @inheritdoc
   */
  typeApprovalYear?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.seatingCapacity"
  })
  seatingCapacity?: number;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.standingCapacity"
  })
  standingCapacity?: number;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.brand"
  })
  brand?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.width"
  })
  width?: number;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.height"
  })
  height?: number;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.length"
  })
  length?: number;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.weight"
  })
  weight?: number;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.weightTotal"
  })
  weightTotal?: number;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.vehicle.registration.speedLimit"
  })
  speedLimit?: number;

  constructor(registration?: Partial<MrfiktivRegistrationDtoGen>) {
    this.firstname = registration?.firstname ?? "";
    this.name = registration?.name ?? "";
    this.street = registration?.street ?? "";
    this.zipCode = registration?.zipCode ?? "";
    this.city = registration?.city ?? "";
    this.huYear = registration?.huYear ?? "";
    this.huMonth = registration?.huMonth ?? "";
    this.manufacturerNameCode = registration?.manufacturerNameCode;
    this.manufacturerTypeCode = registration?.manufacturerTypeCode;
    this.driveTyp = registration?.driveTyp;
    this.identificationnumber = registration?.identificationnumber ?? "";
    this.numberplate = registration?.numberplate ?? "";
    this.firstregistrationDay = registration?.firstregistrationDay ?? "";
    this.firstregistrationMonth = registration?.firstregistrationMonth ?? "";
    this.firstregistrationYear = registration?.firstregistrationYear;
    this.manufacturerName = registration?.manufacturerName ?? "";
    this.manufacturerType = registration?.manufacturerType ?? "";
    this.manufacturerDescription = registration?.manufacturerDescription ?? "";
    this.vehicleClass = registration?.vehicleClass ?? "";

    this.typeApproval = registration?.typeApproval;
    this.typeApprovalDay = registration?.typeApprovalDay;
    this.typeApprovalMonth = registration?.typeApprovalMonth;
    this.typeApprovalYear = registration?.typeApprovalYear;
    this.brand = registration?.brand;

    this.seatingCapacity = Number(registration?.seatingCapacity ?? 0);
    this.standingCapacity = Number(registration?.standingCapacity ?? 0);
    this.width = Number(registration?.width ?? 0);
    this.height = Number(registration?.height ?? 0);
    this.length = Number(registration?.length ?? 0);
    this.weight = Number(registration?.weight ?? 0);
    this.weightTotal = Number(registration?.weightTotal ?? 0);
    this.speedLimit = Number(registration?.speedLimit ?? 0);
  }

  get firstregistrationDate() {
    return `${this.firstregistrationDay}.${this.firstregistrationMonth}.${this.firstregistrationYear}`;
  }
}
type IRegistration = RegistrationBase;
const Registration = Filter.createForClass(RegistrationBase);

export { IRegistration, Registration };

import { MimeTypeEnum } from "@/lib/enum/mime-type.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { MrfiktivMessageAttachmentViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerImageDataAccessLayer } from "@/store/modules/access-layers/partner-image.access-layer";
import { SignDocumentDataAccessLayer } from "@/store/modules/access-layers/sign-document.access-layer";
import { PartnerModule } from "@/store/modules/partner";
import { File, IFile } from "./file.entity";
import { IPartnerImage, PartnerImage } from "./partner-image.entity";
import { IPartnerMessage } from "./partner-message.entity";
import { IReference, Reference } from "./reference.entity";
import { ISignDocument, SignDocument } from "./sign-document.entity";

@IsFilterable
class PartnerMessageAttachmentBase implements MrfiktivMessageAttachmentViewModelGen {
  isLoadingDownload = false;
  isLoadingReference = false;

  get isImage() {
    return this.type === MimeTypeEnum.JPEG || this.type === MimeTypeEnum.PNG;
  }

  get isPdf() {
    return this.type === MimeTypeEnum.PDF;
  }

  get sizeReadable() {
    return `${Math.round((this.size / (1024 * 1024)) * 100) / 100}MB`;
  }

  get typeIcon() {
    if (this.isImage) {
      return "mdi-image";
    } else if (this.isPdf) {
      return "mdi-file";
    } else {
      return "mdi-file-question-outline";
    }
  }

  /**
   * @inheritdoc
   */
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.message.attachments.name" })
  name: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.message.attachments.type",
    config: {
      items: Object.values(MimeTypeEnum).map(e => {
        return {
          text: `enums.MimeTypeEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  type: string | MimeTypeEnum;

  /**
   * @inheritdoc
   */
  @FilterConfig({ type: FilterTypes.NUMBER, displayName: "objects.message.attachments.size" })
  size: number;

  /**
   * @inheritdoc
   */
  @FilterConfig({ type: Reference })
  ref?: IReference;

  populatedReference?: IPartnerImage | ISignDocument | IFile;

  constructor(attachment?: Partial<IPartnerMessageAttachment | MrfiktivMessageAttachmentViewModelGen>) {
    this.name = attachment?.name || "";
    this.type = attachment?.type || "";
    this.size = attachment?.size || 0;
    this.ref = attachment?.ref ? new Reference(attachment.ref) : undefined;
  }

  async getReference(message: IPartnerMessage) {
    if (!this.populatedReference) await this.populateReference(message);
    if (this.populatedReference) return this.populatedReference;

    return undefined;
  }

  async populateReference(message: IPartnerMessage) {
    try {
      this.isLoadingReference = true;

      if (this.isPdf) {
        await this.populateReferencePDF();
      } else if (this.isImage) {
        await this.populateReferenceImage();
      } else {
        await this.populateReferenceElse(message);
      }

      this.isLoadingReference = false;
      return this;
    } catch (e) {
      this.isLoadingReference = false;
      throw e;
    }
  }

  private async populateReferencePDF() {
    if (!this.ref) throw new Error("No reference to populate");

    this.populatedReference = await (
      SignDocumentDataAccessLayer.maps.id.get(this.ref.refId)[0] ??
      new SignDocument({ partnerId: PartnerModule.partner.id, id: this.ref.refId })
    ).fetch();
  }

  private async populateReferenceImage() {
    if (!this.ref) throw new Error("No reference to populate");

    this.populatedReference = await (
      PartnerImageDataAccessLayer.maps.id.get(this.ref.refId)[0] ??
      new PartnerImage({ partnerId: PartnerModule.partner.id, id: this.ref.refId })
    ).fetch();
  }

  private async populateReferenceElse(message: IPartnerMessage) {
    if (!message.attachmentZip) await message.populateAttachmentZip();

    if (!message.attachmentZip) throw new Error("No zip found to populate");

    const attachmentFile = message.attachmentZipFiles.find(f => f.name === this.name);

    if (!attachmentFile) throw new Error("No file found in zip");

    const file = new File(attachmentFile);

    this.populatedReference = file;
  }

  async download(message: IPartnerMessage): Promise<void> {
    try {
      this.isLoadingDownload = true;
      if (!this.populatedReference) await this.getReference(message);
      await this.populatedReference?.download();
      this.isLoadingDownload = false;
    } catch (e) {
      this.isLoadingDownload = false;
      throw e;
    }
  }
}
type IPartnerMessageAttachment = PartnerMessageAttachmentBase;
const PartnerMessageAttachment = Filter.createForClass(PartnerMessageAttachmentBase);

export { IPartnerMessageAttachment, PartnerMessageAttachment };

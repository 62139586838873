/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import { Action } from "vuex-module-decorators";
import { IPageDataProvider } from "../lib/utility/data/page-data-provider.interface";
import { IPaginationParams } from "../lib/utility/data/pagination-params.interface";
import { PageDataHandler } from "./../lib/utility/data/page-data-handler";
import { BaseStore } from "./base.store";

/**
 * Adds pagination to the store
 */
export abstract class PaginatedBaseStore<T extends object, Q extends IPaginationParams> extends BaseStore<T> {
  /**
   * The page provider
   */
  protected abstract _pageProvider: IPageDataProvider<T, Q>;

  /**
   * The pager for executing page requests
   */
  protected abstract _pager: PageDataHandler<T, Q>;

  /**
   * @inheritdoc
   */
  get isLoading() {
    return this._pager.isPageLoading;
  }

  /**
   * @inheritdoc
   */
  get itemsPerPage() {
    return this._pager.itemsPerPage;
  }

  @Action
  setItemsPerPage(itemsPerPage: number) {
    this._pager.itemsPerPage = itemsPerPage;
  }

  /**
   * @inheritdoc
   */
  get pageSizes() {
    return this._pager.pageSizes;
  }

  /**
   * @inheritdoc
   */
  get currentPage() {
    return this._pager.currentPage;
  }

  /**
   * @inheritdoc
   */
  get totalPages() {
    return this._pager.totalPages;
  }

  /**
   * @inheritdoc
   */
  get totalItems() {
    return this._pager.totalItems;
  }

  /**
   * @returns the filtered entities
   * @deprecated use filtered. Backwards compatible getter for `TheLayoutPaginated`.
   */
  get paginationList() {
    return this.filteredAndSorted;
  }

  @Action
  async fetchPage(query: Q): Promise<T[] | undefined> {
    return await this._pager.fetchPage({
      ...query,
      filter: [...this.filters, ...this.hiddenFilter].map(d => d.dtoPagination),
      search: this.search,
      order: PageOrderEnum.DESCENDING,
      itemsPerPage: this.itemsPerPage
    });
  }

  @Action
  async fetchFirstPage(query: Q): Promise<T[] | undefined> {
    return await this._pager.fetchFirstPage({
      ...query,
      filter: [...this.filters, ...this.hiddenFilter].map(d => d.dtoPagination),
      search: this.search,
      order: PageOrderEnum.DESCENDING,
      itemsPerPage: this.itemsPerPage
    });
  }

  @Action
  async fetchNextPage(query: Q): Promise<T[] | undefined> {
    return await this._pager.fetchNextPage({
      ...query,
      filter: [...this.filters, ...this.hiddenFilter].map(d => d.dtoPagination),
      search: this.search,
      order: PageOrderEnum.DESCENDING,
      itemsPerPage: this.itemsPerPage
    });
  }

  /**
   * @deprecated
   */
  @Action
  async fetchAll(query: Q) {
    const itemsPerPage = this._pageProvider.itemsPerPage;
    this._pageProvider.itemsPerPage = 500;
    const res = await this.context.dispatch("fetchFirstPage", query);

    if (res.length) {
      await this.context.dispatch("_fetchAllRest", query).then(() => (this._pageProvider.itemsPerPage = itemsPerPage));
    }
  }

  /**
   * @deprecated
   */
  @Action
  async fetchAllFromBeginning(query: IPaginationParams) {
    this.context.dispatch("fetchAll", query);
  }

  /**
   * @deprecated
   */
  @Action
  private async _fetchAllRest(query: Q) {
    await this.context.dispatch("fetchRemaining", query);
  }

  /**
   * @deprecated
   */
  @Action
  async fetchRemaining(query: Q) {
    const previousPage = this._pageProvider.currentPage;

    if (this._pageProvider.totalPages <= previousPage) {
      return;
    }

    const res = await this.context.dispatch("fetchNextPage", query);
    const newPage = this._pageProvider.currentPage;

    if (res.length && previousPage !== newPage) {
      await this.context.dispatch("fetchRemaining", query);
    }
  }
}

import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { Filter, IsFilterable } from "@/lib/filterable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { handleError } from "@/lib/utility/handleError";
import thgService from "@/services/thg/services/thgService";
import {
  ThgBaseImageGen,
  ThgBillingInfoGen,
  ThgChargingStationDocumentTypeDtoGen,
  ThgChargingStationProofDocumentDtoGen,
  ThgMeterReadingGen,
  ThgRegistrationDtoGen,
  ThgThgViewModelGen,
  ThgTimestampDocumentGen,
  ThgUpdateThgAdminDtoGen
} from "@/services/thg/v1/data-contracts";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { ThgPartnerDataAccessLayer } from "@/store/modules/access-layers/thg-partner.access-layer";
import { ThgDataAccessLayer } from "@/store/modules/access-layers/thg.access-layer";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { PayoutOptionEnum } from "@/views/thg/enum/payout-option.enum";

@IsFilterable
class ThgBase implements IThg, Partial<IEntity<ThgThgViewModelGen>> {
  protected get dataLayer(): AbstractLocalDataAccessLayer<any> {
    return ThgDataAccessLayer;
  }

  id: string;

  get _id() {
    return this.id;
  }

  userId: string;
  partnerId: string;
  promotionId?: string | undefined;
  parentId?: string | undefined;
  vehicleId?: string | undefined;

  billings: ThgBillingInfoGen[];
  code?: string | undefined;
  countryCode?: CountryCodeEnum | undefined;
  decommissioningDate?: string | undefined;
  impactFactor: number;
  impactType: ImpactTypeEnum;
  payoutOption: PayoutOptionEnum = PayoutOptionEnum.REGULAR;
  meterReading?: ThgMeterReadingGen | undefined;
  numberplate: string;
  bonus: number;
  payoutConfiguration: any;
  promotionConfiguration?: any | undefined;
  status: ThgStatusEnum;
  year: number;
  calculationMethod?: string | undefined;
  campaign: string;
  exactEnergyAmountInKwH?: number | undefined;
  isBillingInformationComplete: boolean;
  isMultiyear: number;
  kownFrom?: string | undefined;
  proofs?: ThgChargingStationProofDocumentDtoGen[] | undefined;
  purchasingPriceId?: string | undefined;
  refs?: ThgChargingStationDocumentTypeDtoGen[] | undefined;
  registration: ThgRegistrationDtoGen;
  registrationImages: ThgBaseImageGen[];
  timestamp: ThgTimestampDocumentGen;

  constructor(thg?: Partial<IThg | ThgThgViewModelGen>) {
    this.billings = thg?.billings ?? [];
    this.code = thg?.code;
    this.countryCode = thg?.countryCode as CountryCodeEnum;
    this.decommissioningDate = thg?.decommissioningDate;
    this.impactFactor = thg?.impactFactor ?? 0;
    this.impactType = (thg?.impactType as ImpactTypeEnum) ?? "";
    this.meterReading = thg?.meterReading;
    this.numberplate = thg?.numberplate ?? "";
    this.bonus = thg?.bonus ?? 0;
    this.payoutConfiguration = thg?.payoutConfiguration;
    this.promotionConfiguration = thg?.promotionConfiguration;
    this.payoutOption = thg?.payoutOption as PayoutOptionEnum;
    this.status = (thg?.status as ThgStatusEnum) ?? "";
    this.timestamp = thg?.timestamp as ThgTimestampDocumentGen;
    this.year = thg?.year ?? 0;
    this.id = thg?.id ?? "";
    this.calculationMethod = thg?.calculationMethod;
    this.campaign = thg?.campaign ?? "";
    this.exactEnergyAmountInKwH = thg?.exactEnergyAmountInKwH;
    this.isBillingInformationComplete = thg?.isBillingInformationComplete ?? false;
    this.isMultiyear = thg?.isMultiyear ?? 0;
    this.kownFrom = thg?.kownFrom;
    this.parentId = thg?.parentId;
    this.partnerId = thg?.partnerId ?? "";
    this.promotionId = thg?.promotionId;
    this.proofs = thg?.proofs ?? [];
    this.purchasingPriceId = thg?.purchasingPriceId;
    this.refs = thg?.refs ?? [];
    this.registration = thg?.registration || ({} as ThgRegistrationDtoGen);
    this.registrationImages = thg?.registrationImages ?? [];
    this.userId = thg?.userId ?? "";
    this.vehicleId = thg?.vehicleId;
  }

  private map(thg?: ThgThgViewModelGen) {
    if (thg) {
      this.id = thg.id;
      this.billings = thg.billings ?? [];
      this.code = thg.code;
      this.countryCode = thg.countryCode as CountryCodeEnum;
      this.decommissioningDate = thg.decommissioningDate;
      this.impactFactor = thg.impactFactor;
      this.impactType = thg.impactType as ImpactTypeEnum;
      this.meterReading = thg.meterReading;
      this.numberplate = thg.numberplate;
      this.bonus = thg.bonus;
      this.payoutOption = thg.payoutOption as PayoutOptionEnum;
      this.payoutConfiguration = thg.payoutConfiguration;
      this.promotionConfiguration = thg.promotionConfiguration;
      this.status = thg.status as ThgStatusEnum;
      this.timestamp = thg.timestamp as ThgTimestampDocumentGen;
      this.year = thg.year;
      this.calculationMethod = thg.calculationMethod;
      this.campaign = thg.campaign;
      this.exactEnergyAmountInKwH = thg.exactEnergyAmountInKwH;
      this.isBillingInformationComplete = thg.isBillingInformationComplete;
      this.isMultiyear = thg.isMultiyear;
      this.kownFrom = thg.kownFrom;
      this.parentId = thg.parentId;
      this.partnerId = thg.partnerId;
      this.promotionId = thg.promotionId;
      this.proofs = thg.proofs;
      this.purchasingPriceId = thg.purchasingPriceId;
      this.refs = thg.refs;
      this.registration = thg.registration;
      this.registrationImages = thg.registrationImages;
      this.userId = thg.userId;
      this.vehicleId = thg?.vehicleId;
    }
  }

  async update(silent?: boolean | undefined): Promise<this> {
    const dto: ThgUpdateThgAdminDtoGen = {
      code: this.code,
      countryCode: this.countryCode
    };
    await thgService
      .updateThg(this.partnerId, this.id, dto)
      .then(b => this.map(b))
      .catch(e => {
        handleError(e);
        if (!silent) throw e;
      });

    this.dataLayer.set(this);

    return this;
  }

  async updateStatus(status: ThgStatusEnum, userNotification: boolean): Promise<this> {
    await thgService
      .updateStatus(
        this.partnerId,
        this.id,
        {
          status
        },
        !userNotification
      )
      .then(b => this.map(b));

    this.dataLayer.set(this);

    return this;
  }

  async fetch() {
    await thgService
      .getThgByPartnerId(this.id, this.partnerId)
      .then(b => this.map(b))
      .catch(handleError);

    this.dataLayer.set(this);

    return this;
  }
}

class ThgPartnerBase extends ThgBase {
  protected get dataLayer(): AbstractLocalDataAccessLayer<any> {
    return ThgPartnerDataAccessLayer;
  }
}

type IThg = ThgBase;
const Thg = Filter.createForClass(ThgBase);
const ThgPartner = Filter.createForClass(ThgPartnerBase);

export { IThg, Thg, ThgPartner };

import { Filter, IsFilterable } from "@/lib/filterable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { downloadUrlFromRemoteOrigin } from "@/lib/utility/downloadFileFunc";
import imageService from "@/services/mrfiktiv/services/imageService";
import {
  MrfiktivImageMetaDatViewmodelGen,
  MrfiktivPartnerImageViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerImageDataAccessLayer } from "@/store/modules/access-layers/partner-image.access-layer";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import partnerImageService from "@/services/mrfiktiv/services/partnerImageService";
import { ImageEntity } from "./imageEntity";
import { UserModule } from "@/store/modules/me-user.store";

@IsFilterable
class PartnerImageBase implements IEntity<MrfiktivPartnerImageViewModelGen> {
  /**
   * The id of the image
   * @example 6389d1ae67c5cecbb2762cfa
   */
  id: string;

  /**
   * The id of the user uploaded the image
   * @example 6389d1ae67c5cecbb2762cfa
   */
  userId?: string;

  /**
   * The id of the partner uploaded the image
   * @example 6389d1ae67c5cecbb2762cfa
   */
  partnerId?: string;

  /**
   * The id of the folder
   * @example abc123
   */
  folderName: string;

  /**
   * The name of the original image
   * @example abc123
   */
  name: string;

  url: string;

  /**
   * The type of the image
   * @example damage
   */
  type:
    | "damage"
    | "damageDetail"
    | "plate"
    | "cockpit"
    | "overview"
    | "registration"
    | "registrationBack"
    | "vin"
    | "tuev"
    | "tire"
    | "wheel"
    | "wheelDetail"
    | "scene"
    | "preExisting"
    | "sign"
    | "chargingStationProof"
    | "thgProof"
    | "others"
    | "public"
    | "driverslicensefront"
    | "driverslicenseback"
    | "blueprint"
    | "anonymization";

  /** Meta data of an image */
  metaData?: MrfiktivImageMetaDatViewmodelGen;

  /**
   * Is true if the picture is used in form, report or case.
   * @example damage
   */
  isUsed: boolean;

  /**
   * Where the image was provided from.
   * @example damage
   */
  contactType:
    | "form"
    | "report"
    | "sign"
    | "chargingStation"
    | "booking"
    | "thg"
    | "case"
    | "ticket"
    | "vehicle"
    | "portal"
    | "other"
    | "thirdParty"
    | "anonymization";

  /** The timestamp */
  timestamp: ITimestamp;

  constructor(image?: Partial<MrfiktivPartnerImageViewModelGen>) {
    this.id = image?.id ?? "";
    this.userId = image?.userId;
    this.partnerId = image?.partnerId;
    this.folderName = image?.folderName ?? "";
    this.name = image?.name ?? "";
    this.url = image?.url ?? "";
    this.type = image?.type ?? "overview";
    this.metaData = image?.metaData;
    this.isUsed = image?.isUsed ?? false;
    this.contactType = image?.contactType ?? "other";
    this.timestamp = new Timestamp(image?.timestamp);
  }

  map(image?: MrfiktivPartnerImageViewModelGen | ImageEntity) {
    if (!document) return;

    const i = image as MrfiktivPartnerImageViewModelGen;
    this.userId = i?.userId;
    this.partnerId = i?.partnerId;
    this.folderName = i?.folderName ?? "";
    this.name = i?.name ?? "";
    this.url = i?.url ?? "";
    this.type = i?.type ?? "overview";
    this.metaData = i?.metaData;
    this.isUsed = i?.isUsed ?? false;
    this.contactType = i?.contactType ?? "other";
    this.timestamp = new Timestamp(i?.timestamp);
  }

  async fetch(): Promise<this> {
    if (!this.id) {
      throw new Error("Can not fetch image. No ID provided");
    }

    if (!this.partnerId && !UserModule.isAdmin) {
      throw new Error("Can not fetch image without partnerId");
    }

    let res;
    if (!this.partnerId) {
      res = await imageService.get(this.id);
    } else {
      res = await partnerImageService.getOne(this.partnerId, this.id);
    }

    this.map(res);

    PartnerImageDataAccessLayer.set(this);

    return this;
  }

  async download(): Promise<boolean> {
    return await downloadUrlFromRemoteOrigin(this.url, this.name);
  }
}

type IPartnerImage = PartnerImageBase;
const PartnerImage = Filter.createForClass(PartnerImageBase);

export { IPartnerImage, PartnerImage };

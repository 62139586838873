import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { BookingPageFilterElementGen } from "@/services/booking/v1/data-contracts";
import {
  MrfiktivPageFilterElementGen,
  MrfiktivExtendedPageFilterElementGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { SignPageFilterElementGen } from "@/services/sign/v1/data-contracts";
import { ThgPageFilterElementGen } from "@/services/thg/v1/data-contracts";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";

class PageFilterElementBase
  implements
    MrfiktivExtendedPageFilterElementGen,
    MrfiktivPageFilterElementGen,
    ThgPageFilterElementGen,
    SignPageFilterElementGen,
    BookingPageFilterElementGen {
  get id() {
    return PageFilterElementBase.calcId(this.key, this.specifier);
  }

  key: string;
  operation?: PageFilterOperationEnum;
  value: string;

  /**
   * Contains further information needed when handling filtering (see BaseStore) for special filter types
   */
  specifier?: string;

  type?: PageFilterTypes;

  /**
   * DTO when talking to a pagination endpoint!
   */
  get dtoPagination() {
    return { key: this.key, operation: this.operation, value: this.value };
  }

  /**
   * DTO used to store a page filter for example in context of custom views
   */
  get dtoCustomView() {
    return {
      key: this.key,
      operation: this.operation,
      value: this.value,
      specifier: this.specifier,
      type: this.type
    };
  }

  constructor(
    filter?: Partial<MrfiktivPageFilterElementGen | IPageFilterElement | MrfiktivExtendedPageFilterElementGen>
  );
  constructor(filter?: Pick<IPageFilterElement, "key" | "operation" | "value">) {
    this.key = filter?.key ?? "";
    this.operation = (filter?.operation as PageFilterOperationEnum) ?? PageFilterOperationEnum.EQUAL;
    this.value = filter?.value ?? "";
    this.type = ((filter as MrfiktivExtendedPageFilterElementGen)?.type as PageFilterTypes) ?? PageFilterTypes.STRING;
    this.specifier = (filter as MrfiktivExtendedPageFilterElementGen)?.specifier ?? "";
  }

  static calcId(key: string, specifier?: string): string {
    if (!specifier) {
      return key;
    }

    return `${key}?${specifier}`;
  }
}

type IPageFilterElement = PageFilterElementBase;
const PageFilterElement = PageFilterElementBase;

export { IPageFilterElement, PageFilterElement };

import { render, staticRenderFns } from "./TheThgAdminList.vue?vue&type=template&id=370516f0&scoped=true&"
import script from "./TheThgAdminList.vue?vue&type=script&lang=ts&"
export * from "./TheThgAdminList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "370516f0",
  null
  
)

export default component.exports
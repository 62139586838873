/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

export enum MimeTypeEnum {
  JPEG = "image/jpeg",
  PNG = "image/png",
  PDF = "application/pdf",
  HTML = "text/html",
  CSS = "text/css",
  JSON = "application/json",
  XML = "application/xml",
  JAVASCRIPT = "application/javascript",
  TEXT = "text/plain"
}

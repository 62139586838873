/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateMessageDtoGen,
  MrfiktivCreateTemplateMessageDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivPartnerMessageControllerCreateBatchPayloadGen,
  MrfiktivPartnerMessageControllerCreateFromTemplateParamsGen,
  MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen,
  MrfiktivPartnerMessageViewModelGen,
  MrfiktivUpdateMessageDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PartnerMessage<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags PartnerMessage
   * @name PartnerMessageControllerCreate
   * @summary Create a message
   * @request POST:/partner/{partnerId}/message
   * @secure
   * @response `200` `MrfiktivPartnerMessageViewModelGen` The created message
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerMessageControllerCreate = (partnerId: string, data: MrfiktivCreateMessageDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerMessageViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/message`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerMessage
   * @name PartnerMessageControllerFindAllByPartnerId
   * @summary Get all messages for a partner
   * @request GET:/partner/{partnerId}/message
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivPartnerMessageViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerMessageControllerFindAllByPartnerId = (
    { partnerId, ...query }: MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivPartnerMessageViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/message`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerMessage
   * @name PartnerMessageControllerCreateBatch
   * @summary Create a batch of messages
   * @request POST:/partner/{partnerId}/message/batch
   * @secure
   * @response `200` `(MrfiktivPartnerMessageViewModelGen)[]` The created messages
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerMessageControllerCreateBatch = (
    partnerId: string,
    data: MrfiktivPartnerMessageControllerCreateBatchPayloadGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnerMessageViewModelGen[], MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/message/batch`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerMessage
   * @name PartnerMessageControllerFindOneByPartnerId
   * @summary Get a message
   * @request GET:/partner/{partnerId}/message/{id}
   * @secure
   * @response `200` `MrfiktivPartnerMessageViewModelGen` The found message
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerMessageControllerFindOneByPartnerId = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerMessageViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/message/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerMessage
   * @name PartnerMessageControllerUpdateOneById
   * @summary Update a message
   * @request PUT:/partner/{partnerId}/message/{id}
   * @secure
   * @response `200` `MrfiktivPartnerMessageViewModelGen` The updated message
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerMessageControllerUpdateOneById = (
    partnerId: string,
    id: string,
    data: MrfiktivUpdateMessageDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnerMessageViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/message/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerMessage
   * @name PartnerMessageControllerGetAttachmentsFromInboundMessage
   * @summary (MESSAGE READ) Gets attachments for an inbound message
   * @request GET:/partner/{partnerId}/message/{id}/attachments
   * @secure
   * @response `200` `File` ZIP file containing the attachments
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerMessageControllerGetAttachmentsFromInboundMessage = (
    partnerId: string,
    id: string,
    params: RequestParams = {}
  ) =>
    this.http.request<File, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/message/${id}/attachments`,
      method: "GET",
      secure: true,
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerMessage
   * @name PartnerMessageControllerCreateFromTemplate
   * @summary Create a message
   * @request POST:/partner/{partnerId}/template/{key}/message
   * @secure
   * @response `200` `MrfiktivPartnerMessageViewModelGen` The created message
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerMessageControllerCreateFromTemplate = (
    { partnerId, key, ...query }: MrfiktivPartnerMessageControllerCreateFromTemplateParamsGen,
    data: MrfiktivCreateTemplateMessageDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnerMessageViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/template/${key}/message`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}

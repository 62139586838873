






























































































































import { PartnerEntity } from "@/models/partnerEntity";

import { Component, Prop, Vue } from "vue-property-decorator";
import { AssetRepository, AssetEnum } from "@/lib/AssetRepository";
import { ConfigModule } from "@/store/modules/config";

@Component({})
export default class PartnerFooter extends Vue {
  @Prop()
  partnerProp?: PartnerEntity;

  @Prop()
  color?: string;

  @Prop({ default: "white" })
  fontColor?: string;

  @Prop({ default: true })
  showLogo!: boolean;

  @Prop({ default: "© mint software GmbH." })
  poweredBy!: string;

  get boxShadow() {
    return "0 50vh 0 50vh " + this.color;
  }

  get address() {
    return this.partnerProp?.address;
  }

  get settings() {
    return this.partnerProp?.settings;
  }

  get isWebsitePresent() {
    return this.settings && this.settings?.websites;
  }

  get isOpeningTimeInformationPresent() {
    return this.settings && this.settings?.openingHours;
  }

  get logoDamageReport() {
    return AssetRepository.getAsset(ConfigModule.darkMode, AssetEnum.damageReportLogo);
  }

  get overlaySrc() {
    return AssetRepository.getAsset(ConfigModule.darkMode, AssetEnum.footerBackground);
  }

  goToImprint() {
    this.$router.push({ name: "Imprint" });
  }

  goToPrivacy() {
    this.$router.push({ name: "Privacy" });
  }
}

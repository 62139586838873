








































































import TheAboutList from "@/layouts/navigation/TheAboutList.vue";
import TheAdminList from "@/layouts/navigation/TheAdminList.vue";
import TheNavigationDrawerHeader from "@/layouts/navigation/TheNavigationDrawerHeader.vue";
import TheOrganizationList from "@/layouts/navigation/TheOrganizationList.vue";
import ThePartnerList from "@/layouts/navigation/ThePartnerList.vue";
import ThePartnerSelectorList from "@/layouts/navigation/ThePartnerSelectorList.vue";
import TheReportList from "@/layouts/navigation/TheReportList.vue";
import TheBookingList from "@/layouts/navigation/TheBookingList.vue";
import TheSettingsList from "@/layouts/navigation/TheSettingsList.vue";
import TheThgAboutList from "@/layouts/navigation/TheThgAboutList.vue";
import TheThgAdminList from "@/layouts/navigation/TheThgAdminList.vue";
import TheThgAnalyticsList from "@/layouts/navigation/TheThgAnalyticsList.vue";
import TheThgList from "@/layouts/navigation/TheThgList.vue";
import TheThgPartnerList from "@/layouts/navigation/TheThgPartnerList.vue";
import TheThgProcessingList from "@/layouts/navigation/TheThgProcessingList.vue";
import TheUserList from "@/layouts/navigation/TheUserList.vue";
import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ConfigModule } from "@/store/modules/config";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { UiModule } from "@/store/modules/ui.store";
import { Component, Vue, Watch } from "vue-property-decorator";
import TheDocphantList from "./navigation/TheDocphantList.vue";
import TheNavigationLayout from "./navigation/TheNavigationLayout.vue";
import ThePartnerSettingList from "./navigation/ThePartnerSettingList.vue";
import TheThgBillingList from "./navigation/TheThgBillingList.vue";
import TheThgUnauthenticatedList from "./navigation/TheThgUnauthenticatedList.vue";

@Component({
  components: {
    TheNavigationDrawerHeader,
    TheReportList,
    TheBookingList,
    TheOrganizationList,
    TheAboutList,
    ThePartnerList,
    ThePartnerSettingList,
    TheUserList,
    TheAdminList,
    TheSettingsList,
    ThePartnerSelectorList,
    TheThgList,
    TheThgUnauthenticatedList,
    TheThgAboutList,
    TheThgBillingList,
    TheThgAdminList,
    TheThgProcessingList,
    TheThgPartnerList,
    TheThgAnalyticsList,
    TheNavigationLayout,
    TheDocphantList
  }
})
export default class TheNavigationBase extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;
  selectedItem = 1;

  get navigationSettings() {
    return ConfigModule.navigationSettings;
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  get partnerId() {
    return PartnerModule.partner?.id;
  }

  get drawer() {
    return UiModule.drawer;
  }

  set drawer(val: boolean) {
    UiModule.setDrawer(val);
  }

  @Watch("selectedItem")
  watchSelectedItem() {
    if (!this.$vuetify.breakpoint.mobile) {
      return;
    }
    UiModule.setDrawer(false);
  }

  login() {
    this.$router.push({ name: "Login" });
  }

  get onlineBookingItems(): IMenuListItem[] {
    return [
      {
        icon: "mdi-calendar-outline",
        link: `/partner/${this.partnerId}/booking`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.BOOKING,
          action: ActionEnum.READ,
          field: this.partnerId
        },
        text: "navigation.TheOnlineBookingList.title"
      }
    ];
  }

  get signatureItems(): IMenuListItem[] {
    return [
      {
        icon: "mdi-file-document-outline",
        link: `/partners/${this.partnerId}/document`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.DOCUMENT,
          action: ActionEnum.READ,
          field: this.partnerId
        },
        text: "navigation.TheDigitalSignatureList.title"
      }
    ];
  }

  get projectItems(): IMenuListItem[] {
    return [
      {
        icon: "mdi-check-circle-outline",
        link: `/partner/${this.partnerId}/project`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.TICKET,
          action: ActionEnum.READ,
          field: this.partnerId
        },
        text: "nav.ProjectTable.title"
      }
    ];
  }

  get costItems(): IMenuListItem[] {
    return [
      {
        icon: "mdi-cash-multiple",
        link: `/partner/${this.partnerId}/cost-group`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.COST,
          action: ActionEnum.READ,
          field: this.partnerId
        },
        text: "cost.costs"
      }
    ];
  }

  get customerItems(): IMenuListItem[] {
    return [
      {
        icon: "mdi-account-details-outline",
        text: "nav.PartnerCustomerList.title",
        link: `/partners/${this.partnerId}/customer`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.CUSTOMER_DATA,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      }
    ];
  }

  get anonymizationItems(): IMenuListItem[] {
    return [
      {
        icon: "mdi-incognito",
        text: "nav.AnonymizationTableView.title",
        link: `/partner/${this.partnerId}/anonymization`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.ANONYMIZATION,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      }
    ];
  }
}

import { Filter, IsFilterable } from "@/lib/filterable";
import { downloadUrlFromRemoteOrigin } from "@/lib/utility/downloadFileFunc";

@IsFilterable
class FileBase {
  file: File;

  get url() {
    return URL.createObjectURL(this.file);
  }

  constructor(file: File) {
    this.file = file;
  }

  download() {
    return downloadUrlFromRemoteOrigin(this.url, this.file.name);
  }
}
type IFile = FileBase;
const File = Filter.createForClass(FileBase);

export { File, IFile };

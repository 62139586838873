








import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Vue } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";

@Component({ components: { TheNavigationLayout } })
export default class TheThgAdminList extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  route(route: string) {
    this.$router.push({ name: route });
  }

  get partnerId() {
    return PartnerModule.partner._id;
  }

  get items(): IMenuListItem[] {
    return [
      {
        icon: "mdi-file-multiple-outline",
        text: "navigation.TheThgAdminList.partner",
        link: "/partner",
        isEnabled: true
      },
      {
        icon: "mdi-currency-eur",
        text: "thg.PriceTable.title",
        link: "/price",
        isEnabled: true
      },
      {
        icon: "mdi-account-outline",
        text: "navigation.TheThgAdminList.user",
        link: "/user",
        isEnabled: true
      },
      {
        icon: "mdi-car-multiple",
        text: "navigation.TheThgAdminList.allThgs",
        link: "/thg",
        isEnabled: true
      },
      {
        icon: "mdi-car-multiple",
        text: "navigation.TheThgAdminList.electricVehicles",
        link: "/thg-vehicle",
        isEnabled: true
      },
      {
        icon: "mdi-ev-station",
        text: "report.thgOverview.chargingStation.title",
        link: "/charging-station",
        isEnabled: true
      },
      {
        icon: "mdi-battery-charging",
        text: "report.thgOverview.meterReading.title",
        link: "/meter-reading",
        isEnabled: true,
        badge: `${ThgPortalModule.openMeterReadings}`
      },
      {
        icon: "mdi-car-electric-outline",
        text: "navigation.TheThgAdminList.efahrer",
        link: "/efahrer",
        isEnabled: true
      },
      {
        icon: "mdi-advertisements",
        text: "navigation.TheThgAdminList.advertisement",
        link: "/advertisement",
        isEnabled: true
      },
      {
        icon: "mdi-shield-link-variant",
        text: "views.ThgInsuranceListView.title",
        link: "/insurance",
        isEnabled: true
      },
      {
        icon: "mdi-email-edit-outline",
        text: "navigation.TheThgAdminList.templates",
        link: "/template",
        isEnabled: FeatureModule.isTemplateEnabled
      },
      {
        icon: "mdi-file-cog-outline",
        text: "navigation.ThePartnerList.setting.operations",
        link: "/operation",
        isEnabled: true
      },
      {
        icon: "mdi-content-duplicate",
        text: "navigation.ThePartnerList.setting.duplicates",
        link: "/dup",
        isEnabled: true,
        badge: `${ThgPortalModule.duplicateThg.length}`
      },
      {
        icon: "mdi-star",
        text: "navigation.TheThgAdminList.rating",
        link: "/rating",
        isEnabled: true
      }
    ];
  }
}

import Vue from "vue";
import { MrfiktivHttpClientProvider as Api } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { ImageEntity } from "@/models/imageEntity";

class ImageService {
  ROUTE = "/image";

  async getAll(): Promise<ImageEntity[]> {
    const api = new Api().instance();

    let activities: ImageEntity[] = [];

    try {
      const response = await api.get(this.ROUTE);
      activities = response.data as ImageEntity[];
    } catch (error) {
      Vue.$log.error(error);

      throw error;
    }

    return activities;
  }

  async get(id: string): Promise<ImageEntity> {
    const api = new Api().instance();

    let image: ImageEntity;

    try {
      const response = await api.get(`${this.ROUTE}/${id}`);
      image = response.data as ImageEntity;
    } catch (error) {
      Vue.$log.error(error);

      throw error;
    }

    return image;
  }

  async delete(id: string): Promise<ImageEntity> {
    const api = new Api().instance();

    let deletedImage: ImageEntity;

    try {
      const response = await api.delete(`${this.ROUTE}/${id}`);
      deletedImage = response.data as ImageEntity;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return deletedImage;
  }

  async update(id: string, updateImage: ImageEntity): Promise<ImageEntity> {
    const api = new Api().instance();

    try {
      const response = await api.put(`${this.ROUTE}/${id}`, updateImage);
      updateImage = response.data as ImageEntity;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return updateImage;
  }

  async create(createImage: ImageEntity): Promise<ImageEntity> {
    const api = new Api().instance();

    try {
      const response = await api.post(`${this.ROUTE}`, createImage);
      createImage = response.data as ImageEntity;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return createImage;
  }
}

export default new ImageService();

import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ActivityLog, IActivityLog } from "@/models/activity-log.entity";

export const ActivityLogDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IActivityLog, "refId"> {
  getIdentifier(entity: IActivityLog): string {
    return (entity as any).id;
  }

  mapEntity(entity: IActivityLog): IActivityLog {
    return new ActivityLog(entity);
  }

  mapsConfig = {
    refId: {
      getIdentifier: (entity: IActivityLog) => [entity.source.refId]
    }
  };
})();
